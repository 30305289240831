(function ($, ns) {
  "use strict";

  var moduleEvent = function (context, opts) {
    this.$context = $(context);

    var defaultOpts = {
      pushState: (ns.event && ns.event.opts && ns.event.opts.pushState !== undefined) ? ns.event.opts.pushState : true
    };
    opts = opts || {};
    this.opts = $.extend(defaultOpts, opts);

    this.init();
  };

  moduleEvent.prototype = {
    init: function () {
      var self = this;

      self.registerEvents();

      if (this.opts.pushState) {
        window.history && history.replaceState({
          'module': 'event',
          'href': window.location.href
        }, null, window.location.href);
        window.onpopstate = function (event) {
          if (event.state && event.state.module && event.state.module === 'event' && event.state.href) {
            self.loadPage(event.state.href);
          }
        }
      }

      self.recomputeIndexes();
    },
    registerEvents: function () {
      this.registerPagination();
      this.registerSearch(true);
      this.registerReset();
    },
    recomputeIndexes: function () {
      jQuery('.module-wwp-event').each(function (i, instance) {
        $(instance).data('index', i);
      });
    },
    loadPage: function (href, sourceParam, cb) {
      var source = 'pagination';

      if (!!sourceParam) {
        source = sourceParam;
      }

      var self = this;
      self.$context.addClass('ajax-loading');
      $.get(href, {}, function (res) {
        self.$context.removeClass('ajax-loading');
        var index = self.$context.data('index');
        var $newContext = null;
        if (index) {
          $newContext = $($(res).find('.module-wwp-event')[index]);
          $newContext.data('index', index);
        } else {
          $newContext = $(res).find('.module-wwp-event').first();
        }

        self.handleNewContext($newContext, href, source, cb);
      });
    },
    handleNewContext: function ($newContext, href, source, cb) {
      var self = this;
      var paginationMode = this.$context.data('pagination-mode');

      if (paginationMode === 'append' && source === "pagination") {
        var $newItems = $newContext.find('.items-list > *');
        this.$context.find('.items-list')
          .append($newItems);
        this.$context.find('.pagination').replaceWith($newContext.find('.pagination'));
      } else {
        self.$context.replaceWith($newContext);

        self.$context = $newContext;

        var topPos = self.$context.offset().top;
        if (window.smoothScrollMargin) {
          topPos -= window.smoothScrollMargin;
        }

        $('html,body').animate({
          scrollTop: topPos
        }, 750);
      }

      self.registerEvents();

      if (self.opts.pushState) {
        window.history && history.pushState({'module': 'event', 'href': href}, null, href);
      }

      if (ns.theme && ns.theme.runCurrentPageJs) {
        ns.theme.runCurrentPageJs(self.$context);
      }

      if (window.pew) {
        window.pew.enhanceRegistry(self.$context[0]);
      }

      cb && cb(self.$context, paginationMode, source);
    },
    registerPagination: function () {
      var self = this;

      this.$context.find('.pagination a.navlink').on('click', function (e) {
        e.preventDefault();
        $(this).replaceWith('<span class="loader loader--small"></span>')
        var cb = self.loadPageCallback.bind(self);
        self.loadPage($(this).attr('href'), 'pagination', cb);
      });
    },
    registerSearch: function (autoSubmit) {
      var self = this,
        $filtersForm = this.$context.find('form.event-filters');

      $filtersForm.on('submit', function (e) {
        var $form = $(this);
        e.preventDefault();
        if (!self.$context.hasClass('ajax-loading')) {
          var cb = self.loadPageCallback.bind(self);
          self.loadPage($form.prop('action') + '?' + $form.serialize(), 'search', cb);
        }
      });
      if (autoSubmit) {
        $filtersForm.addClass('auto-submit');

        const handleChange = function () {
          $(this).parent().addClass('currently-loading');
          $filtersForm.submit();
        }

        const EventManager = window.EventManager || $(document);

        EventManager.on('select-change', handleChange);

        this.$context.find('.checkbox, input, select').not('.exclude').on('change', handleChange);
      }
    },
    loadPageCallback: function ($context, paginationMode, source) {
    },
    registerReset: function () {
      var self = this,
        $filtersForm = this.$context.find('.event-filters');
      $filtersForm.find('button[type="reset"]').on('click', function () {
        $filtersForm.find('select, input, textarea').val('');
        $filtersForm.submit();
      });
    }
  };

  if (ns && ns.app) {
    ns.app.registerModule('wwp-event', moduleEvent);
  } else {
    window.pew.addRegistryEntry({key: 'wdf-plugin-event', domSelector: '.module-wwp-event', classDef: moduleEvent});
  }

})(jQuery, window.wonderwp);
